import BraceletJade from '../img/bracelet-jade.jpg';

export default function BraceletBox() {
    return (
        <>
        <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8 lg:pt-16 lg:pb-24 lg:content-center">
            <div className="">
                <img src={BraceletJade} alt="Fake" />
            </div>
            <div>
                <h2 className='text-5xl font-normal mb-2'>Positive Begets Positive</h2>
                <p className='text-xl'>Veritasos, deriving from the root word of 'veritas', which is the Latin word for 'truth', is founded on a principle that takes some a lifetime to understand. All things great and small, alive and otherwise, are composed of energy. This is a simple yet very powerful truth. Learning how to harness this positive energy has been kept a secret amongst the elite for centuries; hidden in plain sight. The fastest way to jumpstart this process, is by channeling that energy through a median and outwards towards others. The old adage, “Tis better to give than to receive” is the simplest and rawest form of this process.</p>
            </div>
        </div>
        </>
    )
}