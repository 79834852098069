import logo from '../img/veritasos.png';
//import braceletring from '../img/bracelet-ring.png';
export default function LogoBox(){
    return (
    <>
        <div className="px-6 lg:px-8">
            <div className="mx-auto max-w-3xl pt-10 pb-16 sm:pt-24 sm:pb-20">
                <div>
                    <h1 className="grid items-center justify-center">
                        <img src={logo} alt="VERITASOS" className='mx-auto w-4/5' />
                    </h1>
                    <p className="mt-6 text-lg leading-8 text-gray-600 sm:text-center">
                    Beaded bracelets blessed in the holy waters of the Dead Sea
                    </p>
                    {/* <p className='grid items-center justify-center mt-10'>
                        <img src={braceletring} alt="VERITASOS Bracelets" />
                    </p> */}
                    <hr className="mt-24 h-px bg-gray-200 border-0 dark:bg-gray-700 w-48 mx-auto"></hr>
                </div>
            </div>
        </div>
    </>
    )
}