import './App.css';
import LogoBox from './components/LogoBox';
import BraceletBox from './components/BraceletBox';
import DeadSea from './components/DeadSea';
import Expectations from './components/Expectations';
import ImageBox from './components/ImageBox';
import Message from './components/Message';
import Soldout from './components/Soldout';
function App() {
  return (
    <>
    <LogoBox/>
    <BraceletBox/>
    <DeadSea/>
    <Expectations/>
    <ImageBox/>
    <Message/>
    <Soldout/>
    </>
  );
}

export default App;
