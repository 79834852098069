import BraceleWood from '../img/bracelet-wood.jpg';

export default function Message() {
    return (
        <>
        <div className="mx-auto max-w-2xl px-4 pt-10 pb-16 sm:px-6 md:grid md:max-w-7xl md:grid-cols-2 md:gap-x-8 md:px-8 md:pt-16 md:pb-24 md:content-center">
            <div className='md:text-right'>
                <p className='text-green-900 text-small ml-auto mb-10'>V E R I T A S O S</p>
                <h2 className='text-5xl font-normal mb-2'>We Can Change The World Today</h2>
                <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
                <p className='text-xl mb-4'>By learning to radiate positive energy into the world and practicing it on a daily basis, we become instruments of good. Spreading this message and practice will not only serve to improve our own daily lives but will also help to change the world for the better. Join us on our mission of positivity.</p>
            </div>
            <div className="">
                <img src={BraceleWood} alt="Fake" />
            </div>
        </div>
        </>
    )
}