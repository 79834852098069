
export default function Expectations(){
    return(
        <>
            <div className="mx-auto mt-6 max-w-7xl text-center sm:px-6 md:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
                <div className="bg-[#f2f2f2] p-6">
                    <p className="text-center text-4xl font-bold">1</p>
                    <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700 w-9 mx-auto"></hr>
                    <p className="text-2xl text-center uppercase font-bold mb-4">Channel</p>
                    <p className="text-xl">Upon receiving your Veritasos bracelet, spend 15 minutes (minimum) in a quiet comfortable place focusing on everything you’re grateful for, while holding the beads in hand or wearing them around your wrist. </p>
                </div>
                <div className="bg-[#dfe3e5] p-6">
                    <p className="text-center text-4xl font-bold">2</p>
                    <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700 w-9 mx-auto"></hr>
                    <p className="text-2xl text-center uppercase font-bold mb-4">Repeat</p>
                    <p className="text-xl">Repeat this process every day for as long as you wish. We here at Veritasos have implemented this routine as part of our daily lives. The bracelets serve not only as reminder, but as a conduit to receive and radiate positive energy throughout the day.</p>
                </div>
                <div className="bg-[#f2f2f2] p-6">
                    <p className="text-center text-4xl font-bold">3</p>
                    <hr className="my-4 h-px bg-gray-200 border-0 dark:bg-gray-700 w-9 mx-auto"></hr>
                    <p className="text-2xl text-center uppercase font-bold mb-4">Share</p>
                    <p className="text-xl">Sharing your positive energy with others will only expedite and enrich the blessings that befall upon you. The blessed Veritasos bracelets are a quick and easy way to spread said positivity through a worthwhile message and genuine intent.</p>
                </div>
            </div>
        </>
    )
}