

export default function DeadSea(){
    return (
    <>
    <div className="mx-auto max-w-7xl text-center px-6 grid grid-cols-1 gap-x-8 bg-bg_deadsea bg-cover lg:bg-cover min-h-fit">
        <div className="text-center">
            <p className="mt-8 mb-8 max-w-2xl text-2xl text-white lg:mx-auto">Veritasos bracelets are more than a stylish fashion piece.  They are blessed in the waters of the Dead Sea, arguable one of the most holy of places on Earth. We include salt from the Dead Sea with the purchase of every bracelet. Take this salt and leave it in the corner of a room to bless your home. We recommend using this salt in the same space used for meditation. </p>
        </div>
    </div>
    </>
    )
}