import BraceletJade from '../img/bracelet-jade-lg.jpg';


export default function Soldout(){
return (
    <>
     <div className="mx-auto max-w-2xl px-6 pt-10 pb-32 md:px-4 text-center">
        <img src={BraceletJade} alt="Bracelet Jade"/>
        <p className='text-2xl mx-auto mt-4'>$400</p>
        <p className='text-4xl mx-auto font-bold uppercase'>SOLD OUT</p>
     </div>
    </>
)
}