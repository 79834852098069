import img_salt from '../img/salt.jpg';
import img_hand from '../img/hand.jpg';
import img_plant from '../img/plant.png';
import img_message from '../img/message.jpg';


export default function ImageBox(){
    return(
        <>
        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 md:grid md:max-w-7xl md:grid-cols-3 md:gap-x-8 md:px-8">
          <div className="aspect-w-3 aspect-h-4  overflow-hidden rounded-lg md:block">
            <img
              src={img_salt}
              alt="Dead Sea Salt"
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="grid grid-cols-2 md:grid-cols-1 md:gap-y-8">
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={img_hand}
                alt="Dead Sea Salt in someone's Hand"
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img
                src={img_plant}
                alt="Beautiful Bracelet"
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-w-4 aspect-h-5 sm:overflow-hidden sm:rounded-lg md:aspect-w-3 md:aspect-h-4">
            <img
              src={img_message}
              alt="Our Message"
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>
        </>
    )
}